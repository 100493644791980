<template>
  <el-drawer
    v-model="visible"
   :with-header="false"
   :destroy-on-close="true"
   class="announcement-drawer"
  >
    <div class="announcement-wrap">
      <i class="close" @click="close">
        <svg viewBox="0 0 14 14">
          <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
        </svg>
      </i>


      <ui-loading class="static" v-if="loading" />

      <div class="announcement-inner" v-else>
        <header>
          <el-image :src="merchant.logo" fit="contain" />
          <h2>{{ merchant.name }}</h2>
        </header>
        <h2 class="announcement-title" v-html="announcement.title"></h2>
        <time class="announcement-date">Last updated: {{ moment(announcement.updated_at).format('MMM DD, YYYY') }}</time>
        <div class="announcement-content" v-html="desc(announcement.description)">
          
        </div>
      </div>
    </div>
  </el-drawer>

</template>

<script setup>
  import { ref } from 'vue';
  import { ElDrawer, ElImage } from 'element-plus';
  import moment from 'moment';

  const visible = ref(false);
  const loading = ref(true);
  const announcement = ref({});

  const props = defineProps(
    {
      merchant: {
        type: Object,
        required: true
      },
      ajax: {
        type: Function,
        required: true
      }
    }
  );

  const getAnnouncement = async (id, user_id = 0) => {
    loading.value = true;
    const { data } = await props.ajax({
      url: `/chatroom/get-announcement-detail?id=${id}${user_id ? `&creator_portal_user_id=${user_id}` : ''}`,
      method: 'GET'
    });
    
    announcement.value = data.data;
    loading.value = false;
  }

  const desc = (text) => {
    // add video into warp
    const video = /<video.*?src="(.*?)".*?\/video>/g;
    const videoWrap = '<div class="video-wrap"><video controls><source src="$1" type="video/mp4"></video></div>';
    const desc = text.replace(video, videoWrap);
    return desc;
  }


  const open = (id, user_id = 0) => {
    getAnnouncement(id, user_id);
    visible.value = true;
  }

  const close = () => {
    visible.value = false;
  }

  defineExpose({
    open,
    close
  });
</script>

<style lang="scss">
  .announcement-drawer {
    width: calc(100% - 300px) !important;
  }

  .announcement-wrap {
    height: 100%;
    padding: 16px 0 0;
    display: grid;
    grid-template-rows: auto 1fr;
    box-sizing: border-box;

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--radius);
      height: 32px;
      width: 32px;
      cursor: pointer;
      margin: 0 24px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--Black-90);
      }

      &:hover {
        background-color: var(--Neu-20);
      }
    }
  }

  .announcement-inner {
    padding: 0 24px;
    overflow: auto;
    mask: linear-gradient(0deg, transparent  0%, #fff 24px, #fff calc(100% - 24px), transparent 100%);

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      padding: 24px 0 0;

      h2 {
        font: var(--medium-16);
        color: var(--Text-primary);
        margin-left: 8px;
      }

      img {
        width: 32px;
        height: 32px;
        border-radius: var(--radius);
      }
    }

    .announcement-title {
      font: var(--medium-30);
      color: var(--Text-primary);
      margin: 16px 0;
    }

    .announcement-date {
      font: var(--medium-14);
      color: var(--Text-secondary);
      margin-bottom: 24px;
      display: block;
    }

    .announcement-content {
      $margin: 16px;

      color: var(--Text-primary);
      padding-bottom: 32px;
      overflow: hidden;
      
      & > * {
        margin-bottom: $margin;
      }

      h1 {
        font: var(--medium-26);
        margin: $margin 0;
      }

      h2 {
        font: var(--medium-24);
        margin: $margin 0;
      }

      h3 {
        font: var(--medium-22);
        margin: $margin 0;
      }
      
      h4 {
        font: var(--medium-16);
        margin: $margin 0;
      }

      h5 {
        font: var(--medium-12);
        margin: $margin 0;
      }

      ul {
        padding-left: 24px;
        margin-bottom: $margin;

        li {
          margin-bottom: $margin/2;
          list-style: disc;
        }
      }

      ol {
        padding-left: 24px;
        margin-bottom: $margin;

        li {
          margin-bottom: $margin/2;
          list-style: decimal;
        }
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: $margin;
        border: 1px solid var(--Border-default);

        th, td {
          border: 1px solid var(--Border-default);
          padding: $margin/2;
        }

        th {
          background-color: var(--Neu-10);
        }
      }

      hr {
        border: none;
        border-top: 1px solid var(--Black-20);
        margin: $margin 0;
      }

      strong {
        font-weight: 700;
      }

      em {
        font-style: italic;
      }

      a {
        color: var(--Link);
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      img {
        max-width: 100%;
        height: auto;
        display: block;
      }

      .video-wrap {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        margin-bottom: $margin;

        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }


  }

</style>