<template>
    <div class="ui-date-picker" v-if="$store.state.user_data">
        <label>
            <i><svg-icon name="date" /></i>
            <span>{{ is_post_pick? 'Posted' :current_text }}:</span>

            <time>
                {{ display_date }} (UTC)
                <DatePickerNext :key="key" :value="custom_date" type="date" format="MMM DD, YYYY" range :clearable="false" @close="alertFn"
                    :shortcuts="shortcuts" :disabled="disabled" :disabled-date="cantPickDate" @change="calendarChange"
                    :popup-class="''" />
            </time>

        </label>
    </div>
</template>

<script>
import DatePickerNext from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Button from '@/components/ui/Button.vue';
import moment from 'moment';

export default {
    name: 'DatePicker',
    emits: ['close','update-date'],
    components: {
        DatePickerNext,
        SvgIcon,
        Button,
    },

    computed: {
        display_date() {
            if (this.custom_date) {
                
                if (moment(this.custom_date[0]).format('YYYYMMDD') === moment(this.custom_date[1]).format('YYYYMMDD')) {
                    return moment(this.custom_date[0]).format('MMM DD, YYYY');
                }
                return `${moment(this.custom_date[0]).format('MMM DD, YYYY')} - ${moment(this.custom_date[1]).format('MMM DD, YYYY')}`;
            }
            return '';
        },
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        date_range: {
            type: Object,
            default: null,
        },

        // maxDate: {
        //     type: String,
        //     default: null,
        // },

        // minDate: {
        //     type: String,
        //     default: null,
        // },
        
        is_post_pick:{
            type: Boolean,
            default: false,
        },
        cus_text:{
            type: String,
            default: '',
        },
        spec_key:{ //給陣列傳值用
            type: Number,
            default: false,
        },
    },

    data() {
        return {
            custom_date: null,
            current_text: 'Last 30 days',
            shortcuts: [
                {
                    text: 'Yesterday',
                    onClick() {
                        let start = moment().add(-1, 'days');
                        let end = moment().add(-1, 'days');
                        this.current_text = 'Yesterday';
                        this.isShortcutSelected=true;
                        return [start.toDate(), end.toDate()];
                    },
                },
                {
                    text: 'Last 7 days',
                    onClick() {
                        let start = moment().add(-7, 'days');
                        let end = moment().add(-1, 'days');
                        this.current_text = 'Last 7 days';
                        this.isShortcutSelected=true;
                        return [start.toDate(), end.toDate()];
                    },
                },
                {
                    text: 'Last 30 days',
                    onClick() {
                        let start = moment().add(-30, 'days');
                        let end = moment().add(-1, 'days');
                        this.current_text = 'Last 30 days';
                        this.isShortcutSelected=true;
                        return [start.toDate(), end.toDate()];
                    },
                },
                {
                    text: 'Last 3 months',
                    onClick() {
                        let start = moment().add(-3, 'month');
                        let end = moment().add(-1, 'days');
                        this.current_text = 'Last 3 months';
                        this.isShortcutSelected=true;
                        return [start.toDate(), end.toDate()];
                    },
                },
                {
                    text: 'Last 365 days',
                    onClick() {
                        let start = moment().add(-1, 'year');
                        let end = moment().add(-1, 'days');
                        this.current_text = 'Last 365 days';
                        this.isShortcutSelected=true;
                        return [start.toDate(), end.toDate()];
                    },
                },
            ],
            open:false,
            maxDate: null,
            minDate: null,
            key:0,
            isShortcutSelected:false,
        }
    },

    watch:{

    },

    methods: {
        makeMaxDate() {
            //&& this.$store.state.user_data.contract_status
            if (this.$store.state.user_data) {
                const contractEndTime = moment(this.$store.state.user_data.contract_end_time);
                const contractStartTime = moment(this.$store.state.user_data.contract_start_time);
                
                if (contractEndTime.isBefore(contractStartTime)) {
                    this.maxDate = moment().add(6, 'years').format('YYYY-MM-DD');
                } else {
                    this.maxDate = contractEndTime.format('YYYY-MM-DD');
                }
                
                this.minDate = contractStartTime.format('YYYY-MM-DD');
            } else {
                this.maxDate = null;
                this.minDate = null;
            }

            if (this.maxDate || this.minDate) {
                //this.shortcuts = null;
                this.handleSortDate();
                this.handleOutsideDate();
                this.setText();
            }else{
                this.handleOutsideDate();
                this.setText();
            }

            this.key += 1;
        },

        calendarChange(date) {
            this.custom_date = date;
            this.start_date = date[0];
            this.end_date = date[1];
            
            //8968的custom texte改邏輯
            if (!this.isShortcutSelected) {
                this.current_text = 'Custom';
            } 
            this.isShortcutSelected = false;
            
            if(this.$route.path === "/creator/list"  && !this.$store.state.product_data_open){
                if(this.$store.state.creator_data_open ){
                    this.$store.state.save_date_text = this.current_text;
                }else{
                    this.$store.state.save_date_text = this.current_text;
                    this.$store.state.save_list_date_text = this.current_text;
                }
            }

            this.$emit('update-date',
                { start_date: moment(this.start_date).format('YYYY-MM-DD'), end_date: moment(this.end_date).format('YYYY-MM-DD') , cus:this.current_text, key:this.spec_key }
            );

            this.setText();

        },

        //需要直接用點擊去分辨this.current_text，這邊就先不使用
        setText() {
            // const i = Number(moment().add(-1, 'days').format('YYYYMMDD'));
            // const e = Number(moment(this.custom_date[1]).format('YYYYMMDD'));
           
            // if (i === e) {
            //     const diff = moment(this.custom_date[1]).diff(moment(this.custom_date[0]), 'days');
            //     const mdiff = moment(this.custom_date[1]).diff(moment(this.custom_date[0]), 'months');

            //     console.log(`mdiff:${mdiff},diff:${diff}`);

            //     if (diff === 0) {
            //         this.current_text = 'Yesterday';
            //     } else if (diff === 6) {
            //         this.current_text = 'Last 7 days';
            //     } else if (diff === 29) {
            //         this.current_text = 'Last 30 days';
            //     } else if (mdiff === 2) {
            //         this.current_text = 'Last 3 months';
            //     } else if (diff === 364 || diff === 365) {
            //         this.current_text = 'Last 365 days';
            //     } else {
            //         this.current_text = 'Custom';
            //     }
            // } else {
            //     this.current_text = 'Custom';
            // }

        },

        cantPickDate(date) {
            if (this.maxDate && this.minDate) {
                let min = new Date(new Date(this.minDate).getTime() - 86400000).toISOString().slice(0, 10);
                return date < new Date(min) || date > new Date(this.maxDate);
            } else {
                return false;
            }
        },

        start() {
            this.makeMaxDate();
            this.current_text=this.cus_text ? this.cus_text:this.current_text;
            
            if(this.$route.path === "/creator/list" ){

                if(this.$store.state.creator_data_open && this.$store.state.save_date_text){
                    this.current_text = this.$store.state.save_date_text;
                }else if( this.$store.state.save_list_date_text ){
                    this.current_text = this.$store.state.save_list_date_text;
                }
            }
        },

        alertFn(){
            this.$emit('close')
        },

        handleMaxMinFun(start,end, num, str ,type ){
            let handleMax= moment(this.maxDate)
            let handleMin=moment(this.minDate);
            let currentMoment = moment();
            let notyetStart= this.minDate && handleMin.isAfter(currentMoment)? true : false;

            //如果合約還沒開始但有限制時間
            if (notyetStart) {

                // 1. start 轉 minDate
                start = handleMin.clone();

                // 2. 重新 end 
                if (num !== undefined && str !== undefined) {
                    //  num 轉正数
                    end = handleMin.clone().add(Math.abs(num), str);

                    // 3. 如果 end 變成早於 this.maxDate， end 變成 handleMax
                    if (this.maxDate && end.isAfter(handleMax)) {
                        end = handleMax.clone();
                    }

                } else {
                    start = handleMin.clone();
                }

            } else {

                let isDoneDate=this.maxDate && handleMax.isBefore(currentMoment);

                // #1: 如果 handleMax 比當前時間早， end 變成 handleMax(這部分主要FOR 客製選單的處理，外部資訊不可以有問題，否則會自動轉乘-30天)
                if (isDoneDate) {
                    end = handleMax.clone(); // 使用 clone 避免修改原始 handleMax
                }

                
                // #2: 如果 start 比 handleMin 早，则 start 设为 handleMin
                if (handleMin.isAfter(start)) {
                    start = handleMin;
                }

                // #3: 如果 handleMin 比 start 早，根據 num 和 str 處理 start(從max時間去推算前一天或前七天...)
                if ( isDoneDate && num !== undefined && str !== undefined) {
                    start = handleMax.clone().add(num, str);
                    //可是時間如果被超越，要調回來
                    if (handleMin.isAfter(start)) {
                        start = handleMin;
                    }
                }

                // 如果 end 晚於 handleMax， end 設為 handleMax
                if (this.maxDate && moment(end).isAfter(handleMax)) {
                    end  = handleMax;
                }
            };

            let ok_value=[moment(start).toDate(), moment(end).toDate()];
            return ok_value;
        },

        handleSortDate(){
            let vi=this;
            
            this.shortcuts= [
                {
                    text: 'Yesterday',
                    onClick() {
                        let start = moment().add(-1, 'days');
                        let end = moment().add(-1, 'days');
                        vi.current_text = 'Yesterday';
                        vi.isShortcutSelected=true;
                        return vi.handleMaxMinFun( start, end, -1, 'days');
                    },
                },
                {
                    text: 'Last 7 days',
                    onClick() {
                        let start = moment().add(-7, 'days');
                        let end = moment().add(-1, 'days');
                        vi.current_text = 'Last 7 days';
                        vi.isShortcutSelected=true;
                        return vi.handleMaxMinFun( start, end , -7, 'days');
                    },
                },
                {
                    text: 'Last 30 days',
                    onClick() {
                        let start = moment().add(-30, 'days');
                        let end = moment().add(-1, 'days');
                        vi.current_text = 'Last 30 days';
                        vi.isShortcutSelected=true;
                        return vi.handleMaxMinFun( start, end , -30, 'days');
                    },
                },
                {
                    text: 'Last 3 months',
                    onClick() {
                        let start = moment().add(-3, 'month');
                        let end = moment().add(-1, 'days');
                        vi.current_text = 'Last 3 months';
                        vi.isShortcutSelected=true;
                        return vi.handleMaxMinFun( start, end , -3, 'month');
                    },
                },
                {
                    text: 'Last 365 days',
                    onClick() {
                        let start = moment().add(-1, 'year');
                        let end = moment().add(-1, 'days');
                        vi.current_text = 'Last 365 days';
                        vi.isShortcutSelected=true;
                        return vi.handleMaxMinFun(start, end , -1, 'year');
                    },
                },
            ];
        },

        handleOutsideDate(){
            if (this.date_range && this.date_range.start_date && this.date_range.end_date) {
                //[moment(this.date_range.start_date).toDate(), moment(this.date_range.end_date).toDate()]
                this.custom_date = this.handleMaxMinFun(this.date_range.start_date, this.date_range.end_date);
            } else {
                //[moment().add(-30, 'days').toDate(), moment().add(-1, 'days').toDate()];
                this.custom_date = this.handleMaxMinFun(moment().add(-30, 'days'), moment().add(-1, 'days') , -30, 'days', 'start' );
            }

        }
    },

    mounted() {
        this.start();
    },
}
</script>
<style lang="scss">
.ui-date-picker {
    &>label {
        display: flex;
        align-items: center;
        border: 1px solid var(--border);
        height: 32px;
        border-radius: 6px;
        cursor: pointer;
        background-color: #fff;
        // box-shadow: inset 0 1px 1px rgba(0,0,0,.075);

        &:hover {
            border-color: var(--blue);
        }

        &>i {
            height: 14px;
            width: 14px;
            margin: 0 10px;

            svg {
                height: 100%;
                width: 100%;
                fill: var(--black-90);
            }
        }

        &>span {
            color: var(--black-60);
            font: var(--font-fg-12);
        }

        .mx-datepicker .mx-icon-calendar {
            display: none !important;
        }

        &>time {
            position: relative;
            color: var(--black-90);
            font: var(--font-fg-12);
            padding: 0 10px 0 5px;
        }

        .mx-datepicker .mx-input {
            color: var(--black-60);
            font: var(--font-fg-12);
            //position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 0;
            padding: 0;
            opacity: 0;
        }

        .mx-datepicker {
            width: auto;
        }

    }

}

.mx-datepicker-main {
    .mx-datepicker-sidebar {
        width: unset !important;
    }

    .mx-datepicker-content {
        margin-left: 120px;

        td:hover {
            background: #dbedfb;
        }
    }
}

.mx-datepicker-main-spec {
    .mx-datepicker-content {
        margin-left: 0px;
    }
}
</style>
